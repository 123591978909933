import React from 'react'
import Adminhead from '../Adminhead/Adminhead'
import Adminhome from "../Adminhome/Adminhome"

const Admin = () => {
  return (
    <div>
        <Adminhead />
        <Adminhome />
    </div>
  )
}

export default Admin